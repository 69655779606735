var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",staticClass:"card-wrap",class:_vm.active ? 'active' : 'inactive',on:{"mousemove":_vm.handleMouseMove,"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":function($event){_vm.active = !_vm.active}}},[_c('div',{staticClass:"card",style:([
      _vm.cardStyle,
      this.dataImage
        ? ''
        : {
            background: '#' + ((Math.random() * 0xffffff) << 0).toString(16),
          } ])},[_c('div',{staticClass:"card-bg",style:([_vm.cardBgTransform, _vm.cardBgImage])}),_c('div',{staticClass:"card-bg cover",style:([
        _vm.cardBgTransform,
        {
          background: ("url(" + _vm.publicPath + "img/team/cover.jpg) no-repeat center center"),
          'background-size': 'cover',
          filter: 'brightness(.7) contrast(1.2)',
        } ])}),_c('div',{staticClass:"card-info card-desc"},[_vm._t("content")],2),_c('div',{staticClass:"card-info"},[_vm._t("header"),_vm._t("role")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }